import styled from '@emotion/styled';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import ClosedIcon from '../../../../assets/toggleIcons/closed.svg?react';
import useIsFeatureFlagActive from '../../../../hooks/useIsFeatureFlagActive';
import { SPACING } from '../../../../styles/spacing';
import { TYPOGRAPHY } from '../../../../styles/typography';
import { Styled as ToggleStyles } from '../../../layout/Collapsible';
import Modal from '../../../modals/Modal';

type Distribution = {
  key: string;
  count: string;
  name: string;
  percent: number;
  price: number;
};

type ReportTop5Props = {
  entities?: string;
  distributions?: readonly Distribution[];
};

const Styled = {
  Ol: styled.ol`
    padding-inline-start: 1.25em;
    > * + * {
      margin-block-start: ${SPACING.md};
    }
    li::marker {
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
    }
    @media print {
      font-size: ${TYPOGRAPHY.fontSize.sm};
    }
  `,
};

function ReportTop5({ entities, distributions }: ReportTop5Props) {
  const moreThan5: boolean = !!(distributions && distributions.length > 5);
  const [showAll, setShowAll] = useState(false);
  const isTop5ShowAllActive = useIsFeatureFlagActive('Reports.feature.top5ShowAll');

  const renderTop5Distributions = () => {
    const top5 = [];

    if (distributions) {
      for (let index = 0; index < 5 && index <= distributions.length - 1; index++) {
        const { name, count, percent, price } = distributions[index];
        top5.push(
          <li key={index}>
            <strong data-testid={`reports-top5-label-${index}`}>{name}</strong>
            {' - '}
            <NumberFormat suffix="%" displayType="text" value={percent} /> {' - '}
            <NumberFormat suffix=" shipments" displayType="text" value={count} />
            {' - '}
            <NumberFormat
              prefix="$"
              suffix=" avg"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              value={price}
              data-testid={`reports-top5-value-${index}`}
            />
          </li>,
        );
      }
    }
    return top5;
  };
  const renderFurtherDistributions = () => {
    const theRest = [];

    if (distributions && moreThan5) {
      for (let index = 5; index <= distributions.length - 1; index++) {
        const { name, count, percent, price } = distributions[index];
        theRest.push(
          <li key={index}>
            <strong data-testid={`reports-further-label-${index}`}>{name}</strong>
            {' - '}
            <NumberFormat suffix="%" displayType="text" value={percent} /> {' - '}
            <NumberFormat suffix=" shipments" displayType="text" value={count} />
            {' - '}
            <NumberFormat
              prefix="$"
              suffix=" avg"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              value={price}
              data-testid={`reports-further-value-${index}`}
            />
          </li>,
        );
      }
    }
    return theRest;
  };

  return (
    <>
      <Styled.Ol>{renderTop5Distributions()}</Styled.Ol>
      {moreThan5 && isTop5ShowAllActive && (
        <>
          <ToggleStyles.ToggleWrapper className="collapsible-toggle-wrapper">
            <ToggleStyles.ToggleLabel type="button" onClick={() => setShowAll(true)}>
              <ToggleStyles.ToggleIconWrapper>
                <ClosedIcon title="Open" />
              </ToggleStyles.ToggleIconWrapper>
              {`Show All ${entities}`}
            </ToggleStyles.ToggleLabel>
          </ToggleStyles.ToggleWrapper>
          <Modal
            open={showAll}
            title={`All ${entities} and Shipments`}
            onClose={() => setShowAll(false)}
            width={600}
          >
            <Styled.Ol>
              {renderTop5Distributions()}
              {renderFurtherDistributions()}
            </Styled.Ol>
          </Modal>
        </>
      )}
    </>
  );
}

export default ReportTop5;
