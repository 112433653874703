import styled from '@emotion/styled';
import isEqual from 'lodash.isequal';
import { ShipmentKey } from '../../gql/graphql';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { formatCurrency } from '../../utils/currency';
import formatDimensions from '../../utils/formatDimensions';
import formatWeight from '../../utils/formatWeight';
import Section, { SectionEntries, SHOW_KEY_ONLY, SHOW_VALUE_ONLY } from './Section';

type CurrentAndOriginal<T> = { current: T; original: T };

export type PackageSummary = {
  packageTypeSummary: string;
  dimensionsSummary: string;
  deliveryConfirmationSummary: string;
  minWeight: number;
  maxWeight: number;
  minInsured: number;
  maxInsured: number;
  packagePreset: {
    title: string;
    irregularPackage: boolean;
    hazardousMaterialsEnabled: boolean;
  } | null;
};
export type SpreadsheetMapping = readonly {
  shipmentKey: ShipmentKey;
  valueOrMappingKey: string;
  id?: string | null;
}[];
type PackageDetailsSectionProps = {
  hideOverrideNotes?: boolean;
  packageSummary: PackageSummary;
  dataSource: string;
  spreadsheetMapping: SpreadsheetMapping;
  singleShipmentCountryCode?: string;
  adjusted?: {
    packageType: CurrentAndOriginal<string>;
    weight: CurrentAndOriginal<number>;
    dimensions: CurrentAndOriginal<{ x: number; y: number; z: number | null }>;
  };
};

const Styled = {
  Strikethrough: styled.del`
    color: ${COLOR.red};
    padding-right: ${SPACING.sm};
  `,
};

const overrideKeyText: { [key in ShipmentKey]?: string } = {
  DIMENSION_X: 'length',
  DIMENSION_Y: 'width',
  DIMENSION_Z: 'height',
  WEIGHT: 'weight',
  WEIGHT_POUNDS: 'weight in pounds',
};

function PackageDetailsSection({
  packageSummary,
  dataSource,
  spreadsheetMapping,
  singleShipmentCountryCode,
  adjusted,
  hideOverrideNotes,
}: PackageDetailsSectionProps) {
  const {
    packageTypeSummary,
    dimensionsSummary,
    deliveryConfirmationSummary,
    minWeight,
    maxWeight,
    minInsured,
    maxInsured,
    packagePreset,
  } = packageSummary;

  const renderRangeText = (min: string, max: string) => `Between ${min} and ${max}`;

  // for each key that can change due to an adjustment, parse the old and new values from the adjustment notes
  const renderAdjustedOrCurrent = <T,>(co: CurrentAndOriginal<T>, f: (a: T) => string) => {
    if (!isEqual(co.current, co.original)) {
      return (
        <>
          <Styled.Strikethrough>{f(co.original)}</Styled.Strikethrough>
          {f(co.current)}
        </>
      );
    }
    return f(co.current);
  };

  const renderNotes = (keys: ShipmentKey[]): SectionEntries =>
    dataSource === 'SPREADSHEET'
      ? spreadsheetMapping
          .filter(({ shipmentKey }) => keys.includes(shipmentKey))
          .map((sm) => [
            SHOW_VALUE_ONLY,
            `Override ${overrideKeyText[sm.shipmentKey]} with data from "${sm.valueOrMappingKey}"`,
          ])
      : [];

  function renderPredefinedOrDimensions<T>(pType: string, dim: T, f: (a: T) => string) {
    if (
      pType === 'Envelope, Padded Envelope, Poly Bag, Soft Pack, or Box in a Bag' ||
      pType === 'Box or Rigid Packaging'
    ) {
      return f(dim);
    }
    return 'Predefined';
  }

  const packageType = adjusted
    ? renderAdjustedOrCurrent(adjusted.packageType, (s) => s)
    : packageTypeSummary;

  const dimensions = adjusted
    ? renderAdjustedOrCurrent(
        {
          current: { ...adjusted.dimensions.current, packageType: adjusted.packageType.current },
          original: { ...adjusted.dimensions.original, packageType: adjusted.packageType.original },
        },
        ({ packageType: pType, ...dims }) =>
          renderPredefinedOrDimensions(pType, dims, formatDimensions),
      )
    : renderPredefinedOrDimensions(packageTypeSummary, dimensionsSummary, (s) => s);

  const weight = (() => {
    if (adjusted) {
      return renderAdjustedOrCurrent(adjusted.weight, (ounces) =>
        formatWeight({ pounds: 0, ounces }),
      );
    }
    if (minWeight !== maxWeight) {
      return renderRangeText(
        formatWeight({ pounds: 0, ounces: minWeight }),
        formatWeight({ pounds: 0, ounces: maxWeight }),
      );
    }
    return formatWeight({ pounds: 0, ounces: maxWeight });
  })();

  const dimensionOverrideNotes = renderNotes(['DIMENSION_X', 'DIMENSION_Y', 'DIMENSION_Z']);
  const weightOverrideNotes = renderNotes(['WEIGHT', 'WEIGHT_POUNDS']);

  const declaredPackageValue = (() => {
    if (!maxInsured) {
      return null;
    }
    if (minInsured !== maxInsured) {
      return renderRangeText(formatCurrency(minInsured), formatCurrency(maxInsured));
    }
    const internationalShipmentInsuranceInfo =
      singleShipmentCountryCode && singleShipmentCountryCode !== 'US'
        ? ' (Insurance may not be available for some Country/Service combinations)'
        : '';
    return `${formatCurrency(maxInsured)}${internationalShipmentInsuranceInfo}`;
  })();

  const entries: SectionEntries = [
    ['Package Type', packageType],
    ['Dimensions', dimensions],
    ...(hideOverrideNotes ? [] : dimensionOverrideNotes),
    ['Weight', weight],
    ...(hideOverrideNotes ? [] : weightOverrideNotes),
    [deliveryConfirmationSummary, SHOW_KEY_ONLY],
    ['Declared Package Value', declaredPackageValue],
  ];
  if (packagePreset) {
    entries.push([
      'Hazardous Materials',
      packagePreset.hazardousMaterialsEnabled ? SHOW_KEY_ONLY : null,
    ]);
    entries.push(['Irregular Package', packagePreset.irregularPackage ? SHOW_KEY_ONLY : null]);
  }
  return (
    <Section
      title={`Package Details${packagePreset?.title ? `: ${packagePreset.title}` : ''}`}
      entries={entries}
    />
  );
}

export default PackageDetailsSection;
