import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../../../styles/breakpoints';
import { GREYSCALE } from '../../../../styles/colors';
import { GUTTER_WIDTH } from '../../../../styles/grid';
import { SPACING } from '../../../../styles/spacing';
import { TYPOGRAPHY } from '../../../../styles/typography';
import { LinkButton } from '../../../Link';
import PageSubtitle from '../../../layout/PageSubtitle';

/**
 * we need to force some styles with !important because the legacy app style.css would overwrite them otherwise
 */
const Styled = {
  SecondaryTitle: styled(PageSubtitle)`
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;
  `,
  Subheading: styled.p`
    margin-left: ${SPACING.none};
    margin-right: ${SPACING.none};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    color: ${GREYSCALE.grey80};
  `,
  Paragraph: styled.p<{ center?: number }>`
    ${({ center }) =>
      center &&
      css`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: ${center}%;
      `}
    margin-bottom: ${SPACING.xl} !important;
  `,
  SmallParagraph: styled.p`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey50};
    margin: ${SPACING.md} 0 !important;
  `,
  MutedParagraph: styled.p`
    font-size: ${TYPOGRAPHY.fontSize.md};
    color: ${GREYSCALE.grey50};
    margin-bottom: ${SPACING.xl} !important;
  `,
  ClaimWrapper: styled.div<{ gradient?: boolean }>`
    box-sizing: border-box;
    height: 100%;

    ${({ gradient }) =>
      gradient &&
      css`
        margin-left: -${GUTTER_WIDTH / 2}px;
        margin-right: -${GUTTER_WIDTH / 2}px;
        padding-left: ${GUTTER_WIDTH / 2}px;
        padding-right: ${GUTTER_WIDTH / 2}px;
        background-color: #90ffff;
        background-image: radial-gradient(circle at 0px 50px, #fff 20%, transparent 100%),
          radial-gradient(ellipse at left -50% top 60%, #00ffa0 0%, transparent 50%),
          radial-gradient(circle at right bottom, #fff 10%, transparent 100%);
        padding-top: 80px;
        padding-bottom: 80px;
      `};

    @media (min-width: ${MEDIA_QUERY.smMin}) {
      margin-left: ${SPACING.md};
      margin-right: ${SPACING.md};
      padding-left: ${SPACING.md};
      padding-right: ${SPACING.md};
    }
  `,
  ServiceClaim: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${SPACING.md};

    span[role='img'] {
      font-size: ${TYPOGRAPHY.fontSize.xxl};
      margin-right: 10px;
      margin-bottom: -3px;
    }
  `,
  PackagesImage: styled.img`
    max-width: 100%;
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      display: block;
      margin: 0 auto;
      max-width: 90%;
      margin-top: ${SPACING.xxl};
      margin-bottom: ${SPACING.xxl};
    }
  `,
  TabletImage: styled.img`
    display: block;
    margin: 45px auto;
    max-width: 95%;
    rotate: -11deg;
  `,
  ButtonBar: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
  `,
  BackLink: styled(LinkButton)`
    height: 83px;
    padding: ${SPACING.none} ${SPACING.xl};
    font-size: ${TYPOGRAPHY.fontSize.lg};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    color: ${GREYSCALE.black};
    text-wrap: nowrap;

    svg {
      margin-right: ${SPACING.sm};
    }
  `,
};

export default Styled;
