import { useMutation, useReactiveVar } from '@apollo/client';
import { batchDetailsPageQuery } from '@ps/complete';
import { ScanFormButton, createScanFormByBatchMutation } from '@ps/scanForms';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import FlashMessages from '../../components/FlashMessages';
import ProgressButton from '../../components/form/ProgressButton';
import { Col, Container, Row } from '../../components/layout/Grid';
import PageLoading from '../../components/loading/PageLoading';
import Modal from '../../components/modals/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import { useDownloadLabelsByBatchQuery } from '../operations/downloadLabelsByBatch.query';
import { useShareDownloadUrlByBatchLazyQuery } from '../operations/shareDownloadUrlByBatch.query';
import downloadUrl from '../utils/downloadUrl';
import PageLayoutSelect from './PageLayoutSelect';
import PdfPreview from './PdfPreview';
import ShareLink from './ShareLink';

type BatchPrintModalProps = {
  open: boolean;
  onClose: () => void;
  batchId: string;
  canCreateScanForm: boolean;
  scanFormUrl: string | null;
};

export default function BatchPrintModal({
  open,
  onClose,
  batchId,
  canCreateScanForm,
  scanFormUrl,
}: BatchPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [createScanForm, { loading: creatingScanForm }] = useMutation(
    createScanFormByBatchMutation,
    {
      // update SCAN Form url in batch and download statuses
      update(cache, { data }) {
        if (!data) {
          return;
        }
        const { batchId, canCreateScanForm, scanFormUrl } = data.createScanFormByBatch;
        cache.updateQuery(
          {
            query: batchDetailsPageQuery,
            variables: { batchId },
          },
          (cacheResult) => {
            if (!cacheResult) {
              return undefined;
            }
            return {
              ...cacheResult,
              batch: {
                ...cacheResult.batch,
                canCreateScanForm,
                scanFormUrl,
              },
            };
          },
        );
      },
    },
  );
  const [createShareLink, { data: shareLinkData }] = useShareDownloadUrlByBatchLazyQuery({
    variables: { batchId },
  });
  const { data, loading, refetch, startPolling, stopPolling } = useDownloadLabelsByBatchQuery({
    variables: { batchId },
    skip: !open, // Run query once the modal is open
    notifyOnNetworkStatusChange: true, // Call onCompleted after each fetch
    onError: () => {
      stopPolling();
    },
    onCompleted: ({ downloadLabelsByBatch: { status } }) => {
      switch (status) {
        case 'IDLE':
        case 'RUNNING':
          startPolling(1000);
          break;
        case 'ERROR':
        case 'FINISHED':
          stopPolling();
          break;
        default:
      }
    },
  });

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null; // TODO: render flash messages
    }
    if (
      !loading &&
      // correct status to show the print preview
      data !== undefined &&
      data.downloadLabelsByBatch.pageLayout &&
      data.downloadLabelsByBatch.fileFormat &&
      data.downloadLabelsByBatch.url &&
      data.downloadLabelsByBatch.status === 'FINISHED'
    ) {
      const { pageLayout, fileFormat, url } = data.downloadLabelsByBatch;

      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(newPageLayout) => {
                  refetch({
                    batchId,
                    pageLayout: newPageLayout,
                  });
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col xs={12} sm={6} spaceBelow>
              <ScanFormButton
                canCreateScanForm={canCreateScanForm}
                scanFormUrl={scanFormUrl}
                loading={creatingScanForm}
                onCreateScanForm={() => {
                  createScanForm({
                    variables: {
                      batchId,
                    },
                  });
                }}
              />
            </Col>
            <Col xs={12} sm={canCreateScanForm || scanFormUrl ? 6 : 12} spaceBelow>
              <ProgressButton
                variant="success"
                fullWidth
                data-testid="download-label-button"
                disabled={false}
                progress={false}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadUrl(url);
                }}
              >
                Download Labels
              </ProgressButton>
            </Col>

            <Col xl={12}>
              <ShareLink
                onClick={() => createShareLink()}
                url={shareLinkData?.shareDownloadUrlByBatch}
              />
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
