import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useIntercom } from 'react-intercom-hook';
import * as yup from 'yup';
import { AddressInput } from '../../gql/graphql';
import { useUpdatePreferredUpsMerchantAccountAddressMutation } from '../../operations/mutations/updatePreferredUpsMerchantAccountAddress';
import { usePreferredMerchantAccountAddressQuery } from '../../operations/queries/preferredMerchantAccountAddress';
import getPostcodeAndZip4 from '../../utils/getPostcodeAndZip4';
import withIntercomLink from '../../utils/withIntercomLink';
import Alert from '../Alert';
import { FakeLink } from '../Link';
import { Col, Row } from '../layout/Grid';
import PageSubtitle from '../layout/PageSubtitle';
import Loading from '../loading/PageLoading';
import PhysicalAddressSubform from '../subforms/PhysicalAddressSubform';
import { physicalAddressValidationSchema } from '../subforms/physicalAddressSubformUtls';
import Modal, { ModalButtonBar } from './Modal';

type UpsAccountCorrectionModalProps = {
  open: boolean;
  onCancel: () => void;
  merchantCreationError: string | undefined;
  onUpdateAddress: () => void;
  merchantAccountSubmitting: boolean;
};

export default function UpsAddressCorrectionModal({
  open,
  onCancel,
  merchantCreationError,
  onUpdateAddress,
  merchantAccountSubmitting,
}: UpsAccountCorrectionModalProps) {
  const validationSchema = useMemo(() => physicalAddressValidationSchema('ups'), []);
  const { show: showIntercomArticle } = useIntercom();
  const { data: preferredMerchantAccountData, loading: addressLoading } =
    usePreferredMerchantAccountAddressQuery('ups');
  const [updatePreferredUpsMerchantAddress, { loading: addressUpdating }] =
    useUpdatePreferredUpsMerchantAccountAddressMutation();

  const updateAddress = async (physicalAddress: AddressInput) => {
    const { postcode, zip4 } = getPostcodeAndZip4(physicalAddress.postcode);
    await updatePreferredUpsMerchantAddress({
      variables: {
        address: {
          fullName: physicalAddress.fullName,
          company: physicalAddress.company,
          address1: physicalAddress.address1,
          address2: physicalAddress.address2,
          postcode,
          zip4,
          regionCode: physicalAddress.regionCode,
          countryCode: physicalAddress.countryCode,
          city: physicalAddress.city,
        },
      },
    });
    onUpdateAddress();
  };
  return (
    <Modal
      width={800}
      open={open}
      onClose={onCancel}
      title="UPS needs more information to continue"
    >
      {!preferredMerchantAccountData ? (
        <Loading height="20vh" />
      ) : (
        <>
          {merchantCreationError && (
            <Alert variant="danger">
              {withIntercomLink(merchantCreationError, showIntercomArticle)}
            </Alert>
          )}
          <Formik
            enableReinitialize
            validationSchema={yup.object({ physicalAddress: validationSchema })}
            initialValues={{
              physicalAddress: preferredMerchantAccountData.company.preferredMerchantAccountAddress,
            }}
            onSubmit={(values) => updateAddress(values.physicalAddress)}
            validateOnMount
          >
            <Form>
              <Row>
                <Col md={12}>
                  UPS needs to confirm the physical address of your home, office, or warehouse.
                </Col>
                <Col md={12}>
                  This will not change the Ship From Address that’s printed on the label.
                </Col>
                <Col md={12}>
                  <PageSubtitle>Update your Physical Address and try again:</PageSubtitle>
                </Col>
                <Col md={12}>
                  <PhysicalAddressSubform namespace="physicalAddress" carrier="ups" />
                </Col>
                <Col md={12}>
                  If you need any help just{' '}
                  <FakeLink
                    onClick={() => {
                      showIntercomArticle();
                      onCancel();
                    }}
                  >
                    chat with our support
                  </FakeLink>
                  !
                </Col>
                <Col md={12}>
                  <ModalButtonBar
                    cancelText="Cancel & Choose a Different Carrier"
                    cancelOnClick={onCancel}
                    buttonText="Try again"
                    buttonVariant="success"
                    buttonType="submit"
                    buttonSize="large"
                    buttonProgress={addressUpdating || merchantAccountSubmitting}
                    buttonDisabled={addressUpdating || addressLoading || merchantAccountSubmitting}
                  />
                </Col>
              </Row>
            </Form>
          </Formik>
        </>
      )}
    </Modal>
  );
}
