import UserEmailVerificationFailedModal from './modals/UserEmailVerificationFailedModal';
import UserEmailVerificationSucceededModal from './modals/UserEmailVerificationSucceededModal';

export default function GlobalUserEmailVerificationModals() {
  return (
    <>
      <UserEmailVerificationSucceededModal />
      <UserEmailVerificationFailedModal />
    </>
  );
}
