import { CustomsFormSubformValues } from '../../components/subforms/CustomsFormSubform';
import { ExtraServicesSubformValues } from '../../components/subforms/ExtraServicesSubform';
import { HazardousMaterialsSubformValues } from '../../components/subforms/HazardousMaterialsSubform';
import { PackageSubformValues } from '../../components/subforms/PackageSubform';
import { INITIAL_CUSTOMS_FORM_SUBFORM_VALUES } from '../../components/subforms/customsFormSubformUtils';
import { INITIAL_EXTRA_SERVICE_SUBFORM_VALUES } from '../../components/subforms/extraServicesSubformUtils';
import { DeliveryConfirmation, ReturnLabel } from '../../gql/graphql';
import { toWeightObject } from '../../utils/formatWeight';

type ShipmentPresetFormValues = {
  package: PackageSubformValues;
  extraServices: ExtraServicesSubformValues;
  hazardousMaterials: HazardousMaterialsSubformValues;
  customsForm: CustomsFormSubformValues;
};

export default function mapPresetToFormValues(
  shipmentPreset: {
    readonly packageTypeKey: string;
    readonly dimensionX: number; // BE sends Number, which we convert later to string to allow fractions
    readonly dimensionY: number;
    readonly dimensionZ: number;
    readonly weight: number;
    readonly deliveryConfirmationFlag: boolean;
    readonly deliveryConfirmation: DeliveryConfirmation;
    readonly returnLabelFlag: boolean;
    readonly returnLabel: ReturnLabel;
    readonly insuredValueFlag: boolean;
    readonly insuredValue: number;
    readonly qualifiesAsMediaMail: boolean;
    readonly irregularPackage: boolean;
    readonly hazardousMaterialsEnabled: boolean;
    readonly customsFormEnabled: boolean;
    readonly customsSigner: string;
    readonly customsContentType: string;
    readonly customsItems: readonly {
      readonly title: string;
      readonly quantity: number;
      readonly itemValue: number;
      readonly weight: number;
      readonly hsTariffNumber: string;
      readonly countryCodeOfOrigin: string;
    }[];
    readonly exporterTaxId: string;
    readonly recipientTaxId: string;
  },
  isCustomsFormRequired: boolean,
  is2x7LabelSize: boolean,
): ShipmentPresetFormValues {
  //  There is no preset for new users or on "Do not use saved package" option

  return {
    package: {
      packageTypeKey: shipmentPreset.packageTypeKey,
      dimensionX: shipmentPreset.dimensionX.toString(),
      dimensionY: shipmentPreset.dimensionY.toString(),
      dimensionZ: shipmentPreset.dimensionZ.toString(),
      weightPounds: toWeightObject(shipmentPreset.weight).pounds,
      weightOunces: toWeightObject(shipmentPreset.weight).ounces,
    },
    extraServices: {
      deliveryConfirmationSelectEnabled: shipmentPreset.deliveryConfirmationFlag,
      // the backend can return 'none', but this value does not exist in the dropdown
      // therefore, when the checkbox gets checked, we have to set the value to our default value
      deliveryConfirmation:
        shipmentPreset.deliveryConfirmation === 'none'
          ? INITIAL_EXTRA_SERVICE_SUBFORM_VALUES.deliveryConfirmation
          : shipmentPreset.deliveryConfirmation,
      returnLabelSelectEnabled: shipmentPreset.returnLabelFlag && !is2x7LabelSize,
      returnLabel: shipmentPreset.returnLabel,
      insuranceInputEnabled: shipmentPreset.insuredValueFlag,
      insuredValue: shipmentPreset.insuredValue,
      isMediaMail: shipmentPreset.qualifiesAsMediaMail,
      isIrregularPackage: shipmentPreset.irregularPackage,
    },
    hazardousMaterials: {
      hazardousMaterialsEnabled: shipmentPreset.hazardousMaterialsEnabled,
    },
    customsForm: {
      customsFormEnabled:
        (shipmentPreset.customsFormEnabled || isCustomsFormRequired) && !is2x7LabelSize,
      customsSigner: shipmentPreset.customsSigner,
      customsContentType:
        shipmentPreset.customsContentType || INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsContentType,
      customsItems:
        shipmentPreset.customsItems.length < 1
          ? INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsItems
          : shipmentPreset.customsItems.map((item) => ({
              title: item.title,
              quantity: item.quantity,
              itemValue: item.itemValue,
              weightPounds: toWeightObject(item.weight).pounds,
              weightOunces: toWeightObject(item.weight).ounces,
              hsTariffNumber: item.hsTariffNumber,
              countryCodeOfOrigin: item.countryCodeOfOrigin,
            })),
      exporterTaxId: shipmentPreset.exporterTaxId,
      recipientTaxId: shipmentPreset.recipientTaxId,
    },
  };
}
