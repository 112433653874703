import { PackageType } from '../../components/subforms/PackageSubform';
import { INITIAL_CUSTOMS_FORM_SUBFORM_VALUES } from '../../components/subforms/customsFormSubformUtils';
import { INITIAL_EXTRA_SERVICE_SUBFORM_VALUES } from '../../components/subforms/extraServicesSubformUtils';
import { INITIAL_HAZARDOUS_MATERIALS_SUBFORM_VALUES } from '../../components/subforms/hazardousMaterialsSubformUtils';
import {
  INITIAL_PACKAGE_SUBFORM_VALUES,
  getInitialPackageTypeKey,
} from '../../components/subforms/packageSubformUtils';
import {
  AddressInput,
  ShipmentPresetInput,
  ShipmentPresetQuery,
  ShipmentPresetsQuery,
} from '../../gql/graphql';
import { toOunces } from '../../utils/formatWeight';
import sanitizePhoneNumber from '../../utils/sanitizePhoneNumber';
import stringToNumber from '../../utils/stringToNumber';
import { UploadFormPageValues } from './UploadFormPage';

export function mapAddressInputToWarehouseAddress(address: AddressInput) {
  return {
    fullName: address.fullName,
    company: address.company,
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    regionCode: address.regionCode,
    postcode: address.postcode,
    countryCode: address.countryCode,
    phone: sanitizePhoneNumber(address.phone),
  };
}

export function mapValuesToPreset({
  values,
  title = '',
  presetId,
  packageTypes,
}: {
  values: UploadFormPageValues;
  packageTypes: readonly PackageType[];
  title?: string;
  presetId?: string;
}): ShipmentPresetInput {
  const { extraServices, customsForm, hazardousMaterials, package: pkg } = values;

  const { dimensionsRequired, heightRequired } = packageTypes.find(
    ({ packageTypeKey }) => packageTypeKey === pkg.packageTypeKey,
  )!;

  return {
    id: presetId,
    title,
    packageTypeKey: values.package.packageTypeKey,
    deliveryConfirmationFlag: extraServices.deliveryConfirmationSelectEnabled,
    deliveryConfirmation: extraServices.deliveryConfirmation,
    returnLabelFlag: extraServices.returnLabelSelectEnabled,
    returnLabel: extraServices.returnLabel,
    insuredValueFlag: extraServices.insuranceInputEnabled,
    insuredValue: Number(extraServices.insuredValue),
    qualifiesAsMediaMail: extraServices.isMediaMail,
    irregularPackage: extraServices.isIrregularPackage,
    hazardousMaterialsEnabled: hazardousMaterials.hazardousMaterialsEnabled,
    // strings, because we allow fractions, e.g. 1 1/2. But BE expects type Number. Empty strings (e.g. no dimensionZ) becomes 0.
    // the check for dimensionsRequired/heightRequired is not necessary, but it improves the data quality
    dimensionX: dimensionsRequired ? stringToNumber(values.package.dimensionX || '') : 0,
    dimensionY: dimensionsRequired ? stringToNumber(values.package.dimensionY || '') : 0,
    dimensionZ: heightRequired ? stringToNumber(values.package.dimensionZ || '') : 0,
    weight: toOunces({
      pounds: Number(values.package.weightPounds),
      ounces: Number(values.package.weightOunces),
    }),
    customsFormEnabled: customsForm.customsFormEnabled,
    customsContentType: customsForm.customsContentType,
    customsSigner: customsForm.customsSigner,
    customsItems: customsForm.customsFormEnabled
      ? customsForm.customsItems.map((item) => ({
          title: item.title,
          quantity: Number(item.quantity),
          itemValue: Number(item.itemValue),
          weight: toOunces({
            pounds: Number(item.weightPounds),
            ounces: Number(item.weightOunces),
          }),
          hsTariffNumber: item.hsTariffNumber ?? '',
          countryCodeOfOrigin: item.countryCodeOfOrigin,
        }))
      : [
          // fix: backend validation should be yup validation
          // these are empty values to satisfy the backend validation
          {
            title: 'empty',
            quantity: 1,
            itemValue: 1,
            weight: 1,
            hsTariffNumber: '',
            countryCodeOfOrigin: '',
          },
        ],
    exporterTaxId: customsForm.exporterTaxId,
    recipientTaxId: customsForm.recipientTaxId,
  };
}

export function getDefaultPreset(
  shipmentPresetsData: ShipmentPresetQuery,
  packageTypes: readonly PackageType[],
) {
  const defaultShipmentPreset = shipmentPresetsData.company.shipmentPresets.find(
    ({ id }) => id === shipmentPresetsData.company.settings.defaultShipmentPresetId,
  );

  if (!defaultShipmentPreset) {
    return undefined;
  }

  const packageTypeKey = getInitialPackageTypeKey(
    packageTypes,
    defaultShipmentPreset.packageTypeKey,
  );

  return { ...defaultShipmentPreset, packageTypeKey };
}

export function isDefaultPreset(shipmentPresetsData: ShipmentPresetQuery, presetId: string) {
  return shipmentPresetsData.company.settings.defaultShipmentPresetId === presetId;
}

export function getPresetTitle(shipmentPresetsData: ShipmentPresetsQuery, presetId: string) {
  return shipmentPresetsData.company.shipmentPresets.find(({ id }) => id === presetId)?.title;
}

export function getInitialPackageDetailsValues(
  userName: string,
  customsFormIsRequired: boolean,
  is2x7LabelSize: boolean,
) {
  return {
    package: {
      ...INITIAL_PACKAGE_SUBFORM_VALUES,
    },
    extraServices: {
      ...INITIAL_EXTRA_SERVICE_SUBFORM_VALUES,
      ...(is2x7LabelSize && { returnLabelSelectEnabled: false }),
    },
    hazardousMaterials: {
      ...INITIAL_HAZARDOUS_MATERIALS_SUBFORM_VALUES,
    },
    customsForm: {
      ...INITIAL_CUSTOMS_FORM_SUBFORM_VALUES,
      customsSigner: userName,
      customsFormEnabled: customsFormIsRequired && !is2x7LabelSize,
    },
  } satisfies Partial<UploadFormPageValues>;
}
