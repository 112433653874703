import styled from '@emotion/styled';
import { getIn, useFormikContext } from 'formik';
import { Subset } from '../../constants';
import { DeliveryConfirmation, ReturnLabel } from '../../gql/graphql';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import convertBbcode from '../../utils/convertBbcode';
import IntercomArticleLink from '../IntercomArticleLink';
import Checkbox from '../form/Checkbox';
import DropdownSelect, { DropdownSelectOption } from '../form/DropdownSelect';
import FormControl from '../form/FormControl';
import TextField from '../form/TextField';
import Collapsible from '../layout/Collapsible';
import { Col, Row } from '../layout/Grid';
import { NamespacedSubform } from './types';

const Styled = {
  ToggleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-bottom: ${SPACING.sm};
  `,
  ExtraServicesWrapper: styled.div`
    border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    padding: ${SPACING.lg};
    background-color: ${GREYSCALE.grey10};
  `,
  SecondaryText: styled.div`
    color: ${GREYSCALE.grey50};
  `,
  LastCol: styled(Col)`
    padding-bottom: ${SPACING.lg};
  `,
};

type ServiceShortNameKey = Subset<
  keyof ExtraServicesSubformValues,
  | 'deliveryConfirmationSelectEnabled'
  | 'returnLabelSelectEnabled'
  | 'insuranceInputEnabled'
  | 'isMediaMail'
  | 'isIrregularPackage'
>;

const serviceShortNames = new Map<ServiceShortNameKey, string>([
  ['deliveryConfirmationSelectEnabled', 'Signature Confirmation'],
  ['returnLabelSelectEnabled', 'Return Labels'],
  ['insuranceInputEnabled', 'Insurance'],
  ['isMediaMail', 'Media Mail'],
  ['isIrregularPackage', 'Irregular Package'],
]);

const deliveryConfirmationOptions: DropdownSelectOption<DeliveryConfirmation>[] = [
  {
    value: 'signature',
    title: 'Signature Confirmation',
    description: 'Signature required for delivery',
  },
  {
    value: 'adult_signature',
    title: 'Adult Signature',
    description: '21+ age verification required for delivery',
  },
];

const returnLabelOptions: DropdownSelectOption<ReturnLabel>[] = [
  {
    value: 'return',
    title: 'Return Labels Only',
    description: 'Reverses addresses & charges postage only if they are used (Domestic Only)',
  },
  {
    value: 'standard_and_return',
    title: 'Return Labels Included',
    description: 'Alternates Outbound and Return Labels for easy return option for every shipment',
  },
];

function getSecondaryLabelTextFromActiveServices(values: Record<ServiceShortNameKey, boolean>) {
  const activeServices = Object.entries(values)
    .filter(([, value]) => !!value)
    .map(([key]) => serviceShortNames.get(key as ServiceShortNameKey)); // must as-ify this as Object.entries can't keep scope small

  if (activeServices.length === 0) {
    return <Styled.SecondaryText> No extra services activated</Styled.SecondaryText>;
  }

  return <Styled.SecondaryText>{activeServices.join(', ')} activated</Styled.SecondaryText>;
}

export type ExtraServicesSubformValues = {
  deliveryConfirmationSelectEnabled: boolean;
  deliveryConfirmation: DeliveryConfirmation;
  returnLabelSelectEnabled: boolean;
  returnLabel: ReturnLabel;
  insuranceInputEnabled: boolean;
  insuredValue?: number | '';
  isMediaMail: boolean;
  isIrregularPackage: boolean;
};

export type ExtraServicesSubformProps<NS extends string> = NamespacedSubform<NS> & {
  shouldShowInsuranceField?: boolean;
  is2x7LabelSize?: boolean;
};

export default function ExtraServicesSubform<NS extends string>({
  namespace,
  shouldShowInsuranceField = true,
  is2x7LabelSize,
}: ExtraServicesSubformProps<NS>) {
  const { values } = useFormikContext<Record<string, unknown>>();
  const {
    deliveryConfirmationSelectEnabled,
    returnLabelSelectEnabled,
    insuranceInputEnabled,
    isMediaMail,
    isIrregularPackage,
  } = getIn(values, namespace) as ExtraServicesSubformValues;

  return (
    <Row>
      <Col md={12} spaceBelow>
        <Collapsible
          label="Extra Services"
          customContent={getSecondaryLabelTextFromActiveServices({
            deliveryConfirmationSelectEnabled,
            returnLabelSelectEnabled,
            insuranceInputEnabled: insuranceInputEnabled && shouldShowInsuranceField,
            isMediaMail,
            isIrregularPackage,
          })}
          initialIsCollapsed={
            !(
              deliveryConfirmationSelectEnabled ||
              returnLabelSelectEnabled ||
              insuranceInputEnabled ||
              isMediaMail ||
              isIrregularPackage
            )
          }
        >
          <Row>
            <Col md={12}>
              <FormControl
                name={`${namespace}.deliveryConfirmationSelectEnabled`}
                as={Checkbox}
                label="Signature Confirmation"
                type="checkbox"
              />
            </Col>
            <Col md={12} spaceBelow>
              {deliveryConfirmationSelectEnabled && (
                <FormControl
                  name={`${namespace}.deliveryConfirmation`}
                  as={DropdownSelect}
                  options={deliveryConfirmationOptions}
                />
              )}
            </Col>
            <Col md={12}>
              <FormControl
                name={`${namespace}.returnLabelSelectEnabled`}
                as={Checkbox}
                label="Return Labels"
                type="checkbox"
                secondaryText="Create a Return Label"
                // Disable return labels if the label size is 2x7"
                {...(is2x7LabelSize && {
                  disabled: true,
                  faded: true,
                  secondaryText: convertBbcode(
                    'This feature is not available for 2x7" labels. You can change your label size in [link=/settings/general]Settings / General Settings[/link].',
                  ),
                })}
              />
            </Col>
            <Col md={12} spaceBelow>
              {returnLabelSelectEnabled && (
                <FormControl
                  name={`${namespace}.returnLabel`}
                  as={DropdownSelect}
                  options={returnLabelOptions}
                />
              )}
            </Col>
            {shouldShowInsuranceField && (
              <>
                <Col md={12}>
                  <FormControl
                    name={`${namespace}.insuranceInputEnabled`}
                    as={Checkbox}
                    label="Insurance"
                    type="checkbox"
                    secondaryText={
                      <>
                        Enter the total value of your shipment to add coverage by InsureShield{' '}
                        <IntercomArticleLink href="https://support.pirateship.com/en/articles/9903380-insureshield-terms-and-conditions-for-insurance">
                          View Pricing, Excluded Items, and Terms
                        </IntercomArticleLink>
                      </>
                    }
                  />
                </Col>
                <Col md={12} spaceBelow>
                  {insuranceInputEnabled && (
                    <FormControl
                      name={`${namespace}.insuredValue`}
                      as={TextField}
                      label="Declared Package Value ($)"
                    />
                  )}
                </Col>
              </>
            )}
            <Col md={12} spaceBelow>
              <FormControl
                name={`${namespace}.isMediaMail`}
                as={Checkbox}
                label="Qualifies for Media Mail"
                type="checkbox"
                secondaryText="Educational material only: books, music, or films (other products or any advertising prohibited)"
              />
            </Col>
            <Styled.LastCol md={12}>
              <FormControl
                name={`${namespace}.isIrregularPackage`}
                as={Checkbox}
                label="Irregular Package"
                type="checkbox"
                secondaryText={
                  <>
                    For unusual package types like tubes, wooden crates, tires, etc.{' '}
                    <IntercomArticleLink href="https://support.pirateship.com/en/articles/5170014-what-is-an-irregular-package">
                      Learn more
                    </IntercomArticleLink>
                  </>
                }
              />
            </Styled.LastCol>
          </Row>
        </Collapsible>
      </Col>
    </Row>
  );
}
