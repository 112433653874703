import { ApolloError, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Form, Formik, FormikValues } from 'formik';
import { ChangeEvent, useState } from 'react';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import { BottomRowActions } from '../../components/form/BottomRowActions';
import FormControl from '../../components/form/FormControl';
import Label from '../../components/form/Label';
import TextArea from '../../components/form/TextArea';
import { Col, Row } from '../../components/layout/Grid';
import Modal from '../../components/modals/Modal';
import { DATE_FORMAT } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import { formatCurrency } from '../../utils/currency';
import disputeAdjustmentsMutation from '../operations/disputeAdjustments.mutation';

const Styled = {
  SmallGreyP: styled.p`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    color: ${GREYSCALE.grey50};
    margin: ${SPACING.xs};
  `,
  Heading: styled.h2`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    color: ${GREYSCALE.grey50};
  `,
};

type DisputeAdjustmentModalProps = {
  shipment: {
    id: string;
    trackingNr: string | null;
    adjustments: ReadonlyArray<{ processedAt: string; notes: ReadonlyArray<string> }> | null;
    totalClientPrice: number | null;
    totalClientPriceOriginal: number | null;
  };
  open: boolean;
  onCloseModal: () => void;
};

export default function DisputeAdjustmentModal({
  shipment,
  open,
  onCloseModal,
}: DisputeAdjustmentModalProps) {
  const maxCharacters = 200;
  const [charactersLeft, setCharactersLeft] = useState(maxCharacters);
  const { formatDate } = useDateInUserTimezone();
  const { trackingNr, adjustments, totalClientPrice, totalClientPriceOriginal } = shipment;
  const cost =
    totalClientPrice != null && totalClientPriceOriginal != null
      ? totalClientPrice - totalClientPriceOriginal
      : null;
  const [disputeAdjustments, { loading }] = useMutation(disputeAdjustmentsMutation);

  // this modal should only be shown if there are any adjustments, to dispute, so if for some reason there are none in the shipment, return null
  if (!adjustments || !adjustments.length) return null;

  const formattedProcessedAtDate = formatDate(
    'local',
    adjustments[0].processedAt,
    DATE_FORMAT.usDate,
  );

  const handleCloseModal = () => {
    setCharactersLeft(200);
    onCloseModal();
  };

  async function handleSubmit(values: FormikValues) {
    try {
      await disputeAdjustments({
        variables: {
          shipmentId: shipment.id,
          disputeReason: values.disputeMessage,
        },
      }).then(() => {
        setFlashMessage('Dispute successfully sent.', 'success');
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach((err) => {
          setFlashMessage(err.message, 'danger');
        });
      }
    } finally {
      handleCloseModal();
    }
  }

  const allNotes = adjustments.map((a) => a.notes).flat(1);

  return (
    <Modal
      title={adjustments.length > 1 ? `Dispute adjustments` : 'Dispute adjustment'}
      width={700}
      open={open}
      onClose={handleCloseModal}
    >
      <Formik initialValues={{ disputeMessage: '' }} onSubmit={(values) => handleSubmit(values)}>
        <Row>
          <Col md={12} spaceBelow>
            <Styled.SmallGreyP data-testid="adjustment-shipment">{`${trackingNr} · ${formatCurrency(
              cost || 0,
            )} adjustment on ${formattedProcessedAtDate}`}</Styled.SmallGreyP>
            {allNotes.map((note, idx) => (
              // we have no better identifying property other than the array key - thus, avoid complaints here!
              // eslint-disable-next-line react/no-array-index-key
              <Styled.SmallGreyP key={`note-${idx}`} data-testid="adjustment-note">
                {note}
              </Styled.SmallGreyP>
            ))}
          </Col>
          <Col md={12} spaceBelow>
            Carriers use automated machines to verify package dimensions. Sometimes, these machines
            make mistakes. To file a dispute, just enter a statement below that describes why this
            adjustment was incorrect 👍
          </Col>
          <Form>
            <Col md={12} spaceBelow>
              <Label
                secondary={`${charactersLeft} ${
                  charactersLeft === 1 ? 'character left' : 'characters left'
                }`}
                htmlFor="disputeMessage"
              >
                Describe why the carrier adjustment was incorrect
              </Label>
              <FormControl
                name="disputeMessage"
                as={TextArea}
                maxLength={maxCharacters}
                rows={5}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setCharactersLeft(maxCharacters - event.target.value.length);
                }}
              />
            </Col>
            <Col md={12}>
              The carrier will review their data of this package passing through their network and
              decide whether the adjustment was a mistake. This process typically takes 2-3 weeks.
              We&apos;ll notify you as soon as they&apos;ve made their decision!
            </Col>
            <Col>
              <BottomRowActions
                primaryAction={{
                  title: 'Submit Dispute',
                  type: 'submit',
                  variant: 'success',
                  size: 'medium',
                  progress: loading,
                }}
              />
            </Col>
          </Form>
        </Row>
      </Formik>
    </Modal>
  );
}
