import add from 'date-fns/add';
import * as yup from 'yup';
import { DATE_FORMAT } from '../../constants';
import { BatchQuery } from '../../gql/graphql';
import { DateInUserTimezoneType } from '../../hooks/useDateInUserTimezone';
import delayStringToDuration from '../../utils/delayStringToDuration';
import newDate from '../../utils/newDate';
import roundToQuarterHour from '../../utils/roundToQuarterHour';
import {
  LegacyNotifyRecipientsSettingsRowType,
  NewNotifyRecipientsSettingsRowType,
  NotifyRecipientsSettingRowValues,
} from './NotifyRecipientsSettingRow';

export const notifyRecipientsSettingRowValidationSchema = (
  finishPurchaseBoxDropdownsEnabled: boolean,
) => {
  return finishPurchaseBoxDropdownsEnabled
    ? yup.object<NewNotifyRecipientsSettingsRowType>({
        enabled: yup.boolean().required(),
        emailTemplate: yup.string(),
        defaultTrackingEmailsDelay: yup.string().when('enabled', {
          is: true,
          then: yup.string().required(),
        }),
      })
    : yup.object<LegacyNotifyRecipientsSettingsRowType>({
        enabled: yup.boolean().required(),
        emailTemplate: yup.string(),
        date: yup.date().required().when('enabled', {
          is: true,
          then: yup.date().required(),
        }),
      });
};

export const legacyNotifyRecipientsSettingRowInitialValues = ({
  defaultTrackingEmailsEnabled,
  defaultTrackingEmailsDelay,
  defaultEmailTemplateId,
  userTimezoneMinutesOffset,
  mailTemplates,
}: {
  defaultTrackingEmailsEnabled: boolean;
  defaultTrackingEmailsDelay: string;
  defaultEmailTemplateId: string;
  userTimezoneMinutesOffset: number;
  mailTemplates: BatchQuery['company']['mailTemplates'];
}) => ({
  enabled: defaultTrackingEmailsEnabled,
  emailTemplate: (() => {
    // Make sure we have a usable default email template
    const defaultEmailTemplate = mailTemplates.find(({ id }) => id === defaultEmailTemplateId);
    if (defaultEmailTemplate === undefined) {
      return mailTemplates[0]?.id ?? '';
    }
    return defaultEmailTemplateId;
  })(),
  date: (() => {
    const trackingEmailsDelay = delayStringToDuration(defaultTrackingEmailsDelay);
    const todayLocalNow = add(newDate('now'), {
      minutes: userTimezoneMinutesOffset,
    });
    return roundToQuarterHour(add(todayLocalNow, trackingEmailsDelay));
  })(),
});

type MailTemplates = ReadonlyArray<{
  readonly __typename: 'MailTemplate';
  readonly id: string;
  readonly subject: string;
  readonly name: string;
  readonly senderEmail: string;
  readonly senderName: string;
  readonly asDefault: boolean;
}>;

export const getDefaultEmailTemplate = (
  defaultEmailTemplateId: string,
  mailTemplates: MailTemplates,
): string => {
  return (
    mailTemplates.find(({ id }) => id === defaultEmailTemplateId)?.id || mailTemplates[0]?.id || ''
  );
};

export function getLegacyNotifyRecipientsDate(
  notifyRecipientsSettingsRowValues: NotifyRecipientsSettingRowValues,
  formatDate: DateInUserTimezoneType['formatDate'],
): string | null {
  if (!('date' in notifyRecipientsSettingsRowValues)) {
    return null;
  }
  const { date, enabled } = notifyRecipientsSettingsRowValues;
  return enabled && date ? formatDate('local', date, DATE_FORMAT.dateTime24) : null;
}

export const getNotifyRecipientsDate = (
  notifyRecipientsSettingsRowValues: NotifyRecipientsSettingRowValues,
  formatDate: DateInUserTimezoneType['formatDate'],
  createDate: DateInUserTimezoneType['createDate'],
) => {
  if (
    !notifyRecipientsSettingsRowValues.enabled ||
    !('defaultTrackingEmailsDelay' in notifyRecipientsSettingsRowValues)
  ) {
    return null;
  }

  if (!notifyRecipientsSettingsRowValues.defaultTrackingEmailsDelay) {
    return null;
  }

  const delayDuration = delayStringToDuration(
    notifyRecipientsSettingsRowValues.defaultTrackingEmailsDelay,
  );
  const now = createDate('now');
  const notifyDate = add(now, delayDuration);

  // if delay is +24h or > 1day, we always want to send the notification at the selected day at 9:00
  if (
    (delayDuration.days && delayDuration.days >= 1) ||
    (delayDuration.hours && delayDuration.hours >= 24)
  ) {
    return formatDate('UTC', notifyDate, 'yyyy-MM-dd 9:00 a');
  }

  // else, (30min - 12h) return now + delay
  return formatDate('UTC', notifyDate, DATE_FORMAT.dateTime12);
};
