import { getIn, useFormikContext } from 'formik';
import { ChangeEvent, ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import CountrySelect from '../../../components/form/CountrySelect';
import FormControl from '../../../components/form/FormControl';
import PhoneTextField from '../../../components/form/PhoneTextField';
import TextField from '../../../components/form/TextField';
import { Col, Row } from '../../../components/layout/Grid';
import { NamespacedSubform } from '../../../components/subforms/types';
import { PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES } from '../../../constants';
import GoogleAddressSuggestTextField from '../GoogleAddressSuggestTextField';

type ShipToAddress = {
  email: string;
  phone: string;
  fullName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  regionCode: string;
  postcode: string;
  countryCode: string;
};

// Add a boolean field for each of the usable fields that is used to track if the field was
// autofilled via address parsing or a selected address suggestion, marking them blue.
export const AUTOFILLED_FIELD_SUFFIX = 'Autofilled';
export type ShipToAddressSubformValues = ShipToAddress & {
  [K in `${keyof ShipToAddress}${typeof AUTOFILLED_FIELD_SUFFIX}`]: boolean;
};

export type ShipToAddressSubformProps<NS extends string> = NamespacedSubform<NS> & {
  addressAutocompleteSettings: {
    addressProvider: string;
    googleApiKey: string;
  };
  countries: ReadonlyArray<{
    countryCode: string;
    name: string;
  }>;
};

export type ShipToAddressSubformHandle = {
  saveSnapshot: () => void;
  clearSnapshot: () => void;
  restoreSnapshot: () => void;
  updateSnapshotField: (
    name: keyof ShipToAddressSubformValues,
    value: ShipToAddressSubformValues[keyof ShipToAddressSubformValues],
  ) => void;
  autofillAddress: (address: Partial<ShipToAddress>) => void;
};

function ShipToAddressSubformComponent<NS extends string>(
  { namespace, addressAutocompleteSettings, countries }: ShipToAddressSubformProps<NS>,
  ref: ForwardedRef<ShipToAddressSubformHandle>,
) {
  const { values, setValues } = useFormikContext<object>();
  const subformValues = getIn(values, namespace) as ShipToAddressSubformValues;
  const preAutofillSnapshot = useRef<Partial<ShipToAddressSubformValues>>();

  // Used to save the address field state before autofilling
  const saveSnapshot = () => {
    preAutofillSnapshot.current = { ...subformValues }; // Shallow copy
  };

  // Clears the snapshot after the user left the address field
  const clearSnapshot = () => {
    preAutofillSnapshot.current = undefined;
  };

  // Restores address fields to their pre-autofill state
  const restoreSnapshot = () => {
    if (preAutofillSnapshot.current) {
      setValues(
        {
          ...values,
          [namespace]: { ...preAutofillSnapshot.current },
        },
        /* shouldValidate: */ false,
      );
    }
  };

  // Update a field in the snapshot, e.g. when the user types in the address field
  const updateSnapshotField = <K extends keyof ShipToAddressSubformValues>(
    name: K,
    value: ShipToAddressSubformValues[K],
  ) => {
    if (preAutofillSnapshot.current) {
      preAutofillSnapshot.current[name] = value;
    }
  };

  // Overwrites address fields with the values of the selected address
  const autofillAddress = (address: Partial<ShipToAddress>) => {
    const newSubformValues: Record<string, unknown> = { ...subformValues };

    Object.entries(address).forEach(([key, value]) => {
      if (key in subformValues) {
        newSubformValues[key] = value;
        newSubformValues[`${key}${AUTOFILLED_FIELD_SUFFIX}`] = value !== '';
      }
    });

    setValues(
      {
        ...values,
        [namespace]: newSubformValues,
      },
      /* shouldValidate: */ false,
    );
  };

  // Expose helper functions to parent component
  useImperativeHandle(ref, () => ({
    saveSnapshot,
    clearSnapshot,
    restoreSnapshot,
    updateSnapshotField,
    autofillAddress,
  }));

  return (
    <Row>
      <Col xs={12} sm={6} spaceBelow>
        <FormControl
          name={`${namespace}.email`}
          as={TextField}
          label="Email (optional)"
          maxLength={40}
          autoComplete="shipping email"
          autofilled={subformValues.emailAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={12} sm={6} spaceBelow>
        <FormControl
          name={`${namespace}.phone`}
          as={PhoneTextField}
          label="Phone (optional)"
          countryCode={subformValues.countryCode}
          maxLength={40}
          autoComplete="shipping tel-national"
          autofilled={subformValues.phoneAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={12} sm={6} spaceBelow>
        <FormControl
          name={`${namespace}.fullName`}
          as={TextField}
          label="Name"
          maxLength={40}
          autoComplete="shipping name"
          autofilled={subformValues.fullNameAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={12} sm={6} spaceBelow>
        <FormControl
          name={`${namespace}.company`}
          as={TextField}
          label="Company (optional)"
          maxLength={40}
          autoComplete="shipping organization"
          autofilled={subformValues.companyAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={12} sm={6} spaceBelow>
        {(() => {
          const commonAddressFieldProps: React.ComponentProps<
            typeof FormControl<typeof TextField>
          > = {
            name: `${namespace}.address1`,
            label: 'Address',
            maxLength: 35,
            autoComplete: 'shipping address-line1',
            autofilled: subformValues.address1Autofilled,
            ...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES,
          };

          // Switch address field component based on selected address provider.
          // Shows a plain text field in case the address provider is not supported yet.
          switch (addressAutocompleteSettings.addressProvider) {
            case 'google':
              return (
                <FormControl
                  {...commonAddressFieldProps}
                  as={GoogleAddressSuggestTextField}
                  googlePlacesApiKey={addressAutocompleteSettings.googleApiKey}
                  onFocus={saveSnapshot}
                  onBlur={clearSnapshot}
                  onSelectAddress={autofillAddress}
                  onBlurAddress={restoreSnapshot}
                  onConfirmAddress={clearSnapshot}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateSnapshotField('address1', event.target.value);
                  }}
                />
              );

            default:
              return <FormControl {...commonAddressFieldProps} as={TextField} />;
          }
        })()}
      </Col>
      <Col xs={12} sm={6} spaceBelow>
        <FormControl
          name={`${namespace}.address2`}
          as={TextField}
          label="Apt / Unit / Suite / etc. (optional)"
          maxLength={35}
          autoComplete="shipping address-line2"
          autofilled={subformValues.address2Autofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={4} md={2} spaceBelow>
        <FormControl
          name={`${namespace}.city`}
          as={TextField}
          label="City"
          maxLength={40}
          autoComplete="shipping address-level2"
          autofilled={subformValues.cityAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={4} md={2} spaceBelow>
        <FormControl
          name={`${namespace}.regionCode`}
          as={TextField}
          label="State"
          autoComplete="shipping address-level1"
          autofilled={subformValues.regionCodeAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col xs={4} md={2} spaceBelow>
        <FormControl
          name={`${namespace}.postcode`}
          as={TextField}
          label="Zipcode"
          maxLength={10}
          autoComplete="shipping postal-code"
          autofilled={subformValues.postcodeAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
      <Col md={6} sm={12}>
        <FormControl
          name={`${namespace}.countryCode`}
          as={CountrySelect}
          options={countries}
          autoComplete="shipping country"
          autofilled={subformValues.countryCodeAutofilled}
          {...PASSWORD_MANAGER_SUPPRESS_ATTRIBUTES}
        />
      </Col>
    </Row>
  );
}

const ShipToAddressSubform = forwardRef(ShipToAddressSubformComponent);
export default ShipToAddressSubform;
