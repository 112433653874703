import { DisputeAdjustmentModal } from '@ps/adjustments';
import { CarrierLiabilityHint, ClaimInsureShieldInsuranceLink } from '@ps/insurance';
import { ScheduleEmailNotificationModal, ShipmentEmailNotificationRow } from '@ps/trackingEmails';
import { useState } from 'react';
import IntercomArticleLink, { IntercomArticleIconLink } from '../../components/IntercomArticleLink';
import Link, { LinkButton } from '../../components/Link';
import HeaderRow from '../../components/serviceSummaryTable/HeaderRow';
import ServiceSummaryPrices from '../../components/serviceSummaryTable/ServiceSummaryPrices';
import ServiceSummaryTable from '../../components/serviceSummaryTable/StyledServiceSummaryTable';
import TotalCostRow from '../../components/serviceSummaryTable/TotalCostRow';
import { DATE_FORMAT, INTERCOM_ARTICLE_LINKS } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import { COLOR } from '../../styles/colors';
import { formatCurrency } from '../../utils/currency';
import type {
  Adjustment,
  BatchForShipmentServiceSummary,
  Surcharge,
} from '../types/ShipmentServiceTypes';
import ShipDateRow from './ShipDateRow';
import ServiceSummaryServiceTitle from './ShipmentServiceSummaryServiceTitle';

type ShipmentServiceSummaryTableProps = {
  batch: BatchForShipmentServiceSummary;
};

export default function ShipmentServiceSummaryTable({ batch }: ShipmentServiceSummaryTableProps) {
  const [scheduleEmailNotificationModalOpen, setScheduleEmailNotificationModalOpen] =
    useState<boolean>(false);
  const [isDisputeModalOpen, setIsDisputeModalOpen] = useState(false);
  const { formatDate } = useDateInUserTimezone();

  const shipment = batch.shipments[0]; // we query only the one shipment in the batch that is relevant for this page, so we can name the shipment as such here.
  if (!shipment) return null;

  const isShipmentRefunded = shipment.status === 'REFUNDED';

  const { disputableAdjustmentsCount } = shipment;

  const renderDeliveryInformation = () => {
    const { deliveryEstimation } = shipment;
    if (shipment.recipientAddress.countryCode !== 'US') return null;

    if (!deliveryEstimation?.arrivalLocalDateTime || !deliveryEstimation?.pickupLocalDateTime) {
      return null;
    }
    const arrivalDate = formatDate(
      'local',
      deliveryEstimation.arrivalLocalDateTime,
      DATE_FORMAT.usDate,
    );
    const arrivalTime = formatDate(
      'local',
      deliveryEstimation.arrivalLocalDateTime,
      DATE_FORMAT.time12NoLeadingZero,
    );
    const pickupLocalDateTime = formatDate(
      'local',
      deliveryEstimation.pickupLocalDateTime,
      DATE_FORMAT.usDate,
    );
    if (arrivalDate.length === 0 || arrivalTime.length === 0 || pickupLocalDateTime.length === 0) {
      return null;
    }

    return `Estimated Delivery ${arrivalDate} by ${arrivalTime} if shipped on ${pickupLocalDateTime}`;
  };

  const renderServiceSubtitle = () => {
    const parts = [
      shipment.insuranceDetails.hasOnlyCarrierLiabilityInsurance && (
        <CarrierLiabilityHint
          shipmentId={shipment.id}
          insuranceDetails={shipment.insuranceDetails}
        />
      ),
      renderDeliveryInformation(),
    ].filter((n) => n);

    if (parts.length === 0) {
      return null;
    }

    return (
      <ServiceSummaryTable.SubText data-testid="sub-text">
        {parts.map((p, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ServiceSummaryTable.SubTextItem key={i}>{p}</ServiceSummaryTable.SubTextItem>
        ))}
      </ServiceSummaryTable.SubText>
    );
  };

  const renderAdjustmentsNotes = (adjustments: ReadonlyArray<Adjustment> | null) => {
    if (!adjustments || !adjustments.length) return false;

    const carrier = shipment.carrierKey.toUpperCase();

    return (
      <div data-testid="adjustments">
        {adjustments.map((adjustment) => {
          const { disputeStatus, eligibilityIssueMessage, notes } = adjustment;
          const [createdAt, disputedAt, disputeProcessedAt] = [
            adjustment.createdAt,
            adjustment.disputedAt,
            adjustment.disputeProcessedAt,
          ].map((date) =>
            formatDate('UTC', date ?? 'now', `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate}`),
          );
          return (
            <div key={adjustment.id} data-testid="adjustment">
              <Link
                bridgeHref={`/reports/carrieradjustment?shipment_id=${shipment.id}`}
                to={`/reports/carrieradjustment?shipment_id=${shipment.id}`}
              >
                {`${carrier} adjusted your shipment on ${createdAt}:`}
              </Link>
              {notes.map((note, idx) => (
                // we have no better identifying property other than the array key - thus, avoid complaints here!
                // eslint-disable-next-line react/no-array-index-key
                <ServiceSummaryTable.AdjustmentNote key={`note-${idx}`}>
                  {note}
                </ServiceSummaryTable.AdjustmentNote>
              ))}
              {disputeStatus === 'DISPUTE_APPROVED' && `Dispute approved on ${disputeProcessedAt}`}
              {disputeStatus === 'DISPUTE_DENIED' && `Dispute denied on ${disputeProcessedAt}`}
              {disputeStatus === 'DISPUTE_PENDING' &&
                `Adjustment disputed on ${disputedAt}, pending ${carrier} approval`}
              {disputeStatus === 'UNDISPUTED' &&
                eligibilityIssueMessage &&
                `${eligibilityIssueMessage}`}
            </div>
          );
        })}
        {disputableAdjustmentsCount > 0 && (
          <LinkButton onClick={() => setIsDisputeModalOpen(true)}>
            {disputableAdjustmentsCount > 1 ? 'Dispute all adjustments' : 'Dispute this adjustment'}
          </LinkButton>
        )}
      </div>
    );
  };

  const renderShipmentSurcharges = (surcharges: ReadonlyArray<Surcharge> | null) => {
    if (!surcharges) return false;

    return surcharges.map((surcharge) => {
      const isNowMoreExpensive = surcharge.clientPriceOriginal < surcharge.clientPrice;
      const isInsuredMailSurcharge =
        surcharge.apiKey === 'InsuredMail' || surcharge.apiKey === 'InsuredMailInsureShield';

      // special row: insured mail surcharge
      if (isInsuredMailSurcharge) {
        return (
          <ServiceSummaryTable.Row key={surcharge.title} white data-testid="surcharge-row">
            <ServiceSummaryTable.Col>
              <ServiceSummaryTable.SurchargeTitle
                color={isNowMoreExpensive ? COLOR.red : 'inherit'}
              >
                {`${surcharge.title} (${formatCurrency(
                  shipment.insuranceDetails.insuredValue || 0,
                )} insured value)`}
              </ServiceSummaryTable.SurchargeTitle>{' '}
              {surcharge.apiKey === 'InsuredMailInsureShield' ? (
                <ClaimInsureShieldInsuranceLink
                  shipmentId={shipment.id}
                  data-dd-action-name="insureshield-extra-insurance-clickout"
                />
              ) : (
                <IntercomArticleLink
                  href={INTERCOM_ARTICLE_LINKS.fileInsurance}
                  data-dd-action-name="open-intercom-insurance-modal"
                >
                  File an Insurance Claim
                </IntercomArticleLink>
              )}
            </ServiceSummaryTable.Col>
            <ServiceSummaryTable.Col alignRight>
              <ServiceSummaryPrices
                previousPrice={surcharge.clientPriceOriginal}
                currentPrice={isShipmentRefunded ? 0 : surcharge.clientPrice}
              />
            </ServiceSummaryTable.Col>
          </ServiceSummaryTable.Row>
        );
      }

      return (
        <ServiceSummaryTable.Row key={surcharge.title} white data-testid="surcharge-row">
          <ServiceSummaryTable.Col>
            <ServiceSummaryTable.SurchargeTitle color={isNowMoreExpensive ? COLOR.red : 'inherit'}>
              {surcharge.title}
            </ServiceSummaryTable.SurchargeTitle>
            {surcharge.helpLink && (
              <IntercomArticleIconLink
                url={surcharge.helpLink}
                title="Surcharges Help"
                data-testid={`help-link-${surcharge.apiKey}`}
              />
            )}
          </ServiceSummaryTable.Col>
          <ServiceSummaryTable.Col alignRight>
            <ServiceSummaryPrices
              previousPrice={surcharge.clientPriceOriginal}
              currentPrice={isShipmentRefunded ? 0 : surcharge.clientPrice}
            />
          </ServiceSummaryTable.Col>
        </ServiceSummaryTable.Row>
      );
    });
  };

  return (
    <>
      {shipment.adjustments && shipment.adjustments.length > 0 && (
        <DisputeAdjustmentModal
          open={isDisputeModalOpen}
          shipment={shipment}
          onCloseModal={() => setIsDisputeModalOpen(false)}
        />
      )}
      <ScheduleEmailNotificationModal
        batchId={batch.id}
        selectedTemplateId={batch.emailNotificationTemplate?.id}
        open={scheduleEmailNotificationModalOpen}
        onClose={() => setScheduleEmailNotificationModalOpen(false)}
        notifyRecipientsDate={batch.notifyRecipientsDate}
      />
      <ServiceSummaryTable.Content data-testid="summary-table">
        <HeaderRow />
        <ServiceSummaryTable.Row white>
          <ServiceSummaryTable.Col>
            <ServiceSummaryTable.Spacer>
              <div>
                <ServiceSummaryServiceTitle shipmentServiceDetails={shipment} />
                {renderServiceSubtitle()}
              </div>
              {renderAdjustmentsNotes(shipment.adjustments)}
            </ServiceSummaryTable.Spacer>
          </ServiceSummaryTable.Col>
          <ServiceSummaryTable.Col alignRight>
            <ServiceSummaryPrices
              previousPrice={shipment.baseClientPriceOriginal}
              currentPrice={isShipmentRefunded ? 0 : shipment.baseClientPrice}
            />
          </ServiceSummaryTable.Col>
        </ServiceSummaryTable.Row>
        {renderShipmentSurcharges(shipment.surcharges)}
        <TotalCostRow
          previousCost={isShipmentRefunded ? 0 : shipment.totalClientPriceOriginal}
          currentCost={isShipmentRefunded ? 0 : shipment.totalClientPrice}
          status={shipment.status}
        />
        <ShipDateRow shipDate={batch.shipDate} isBatch={false} />
        <ShipmentEmailNotificationRow
          onClickDate={() => setScheduleEmailNotificationModalOpen(true)}
          additionalTrackingEmailCopy={shipment.order?.platform.additionalTrackingEmailCopy}
          batch={batch}
          shipment={shipment}
        />
      </ServiceSummaryTable.Content>
    </>
  );
}
