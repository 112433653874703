import * as yup from 'yup';
import { SaveOrUpdatePackageSubformValues } from './saveOrUpdatePackageSubform';

export const saveOrUpdatePackageSchema = () =>
  yup.object<SaveOrUpdatePackageSubformValues>({
    updatePreset: yup.boolean().defined(),
    createNewPreset: yup.boolean().defined(),
    newPresetTitle: yup.string().when('createNewPreset', {
      is: true,
      then: yup.string().required('Title is required').max(34),
      otherwise: yup.string().notRequired(),
    }),
  });

export const INITIAL_SAVE_OR_UPDATE_PACKAGE_SUBFORM_VALUES: SaveOrUpdatePackageSubformValues = {
  updatePreset: false,
  createNewPreset: false,
  newPresetTitle: '',
};
