import { ReactiveVar } from '@apollo/client';
import { CurrentUser, currentUserVar as reactiveVar } from '../../apollo/cache';

function createCurrentUser(currentUserVar: ReactiveVar<CurrentUser | null>) {
  return (user: CurrentUser | null) => {
    currentUserVar(user);
  };
}

export default createCurrentUser(reactiveVar);
