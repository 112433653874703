import styled from '@emotion/styled';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import ClosedIcon from '../../assets/toggleIcons/closed.svg?react';
import OpenIcon from '../../assets/toggleIcons/open.svg?react';
import { CollapsibleCollapsedStorageKeyTemplate } from '../../constants';
import { getItem, setItem } from '../../services/storage';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import { Col, Row } from './Grid';

// eslint-disable-next-line react-refresh/only-export-components
export const Styled = {
  ToggleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  CustomContent: styled.div`
    display: flex;
    align-items: center;
    margin-left: ${SPACING.sm};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    flex-wrap: wrap;
  `,
  ToggleLabel: styled.button`
    background: none;
    border: none;
    display: flex;
    align-items: left;
    text-align: left;
    padding: ${SPACING.xs} ${SPACING.none};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    color: ${GREYSCALE.grey80};
    cursor: pointer;

    :focus-visible {
      outline-color: ${COLOR.blue};
      outline-offset: ${SPACING.sm};
    }
  `,
  ToggleIconWrapper: styled.span`
    display: inline-block;
    color: ${GREYSCALE.grey50};
    margin-right: ${SPACING.sm};

    > svg {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  `,
  CollapsibleAreaWrapper: styled.div`
    margin-top: ${SPACING.sm};
    border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    padding: ${SPACING.lg} ${SPACING.lg} ${SPACING.none} ${SPACING.xl};
    background-color: ${GREYSCALE.grey10};
  `,
  HiddenInput: styled.input`
    position: absolute;
  `,
};

type CollapsibleProps = PropsWithChildren<{
  label: string;
  customContent?: ReactNode;
  initialIsCollapsed?: boolean;
}>;

export default function Collapsible({
  children,
  label,
  customContent,
  initialIsCollapsed,
}: CollapsibleProps) {
  const storageKey: CollapsibleCollapsedStorageKeyTemplate = `collapsibleCollapsed_${label}`;
  const c = getItem(storageKey); // get sticky isCollapsed state from local storage

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    (() => {
      // if initialIsCollapsed is set, prefer that over the locally stored "sticky" property
      if (initialIsCollapsed !== undefined) return initialIsCollapsed;
      if (c === null) return true;
      return c;
    })(),
  );

  useEffect(() => {
    setItem(storageKey, isCollapsed);
  }, [storageKey, isCollapsed]);

  const toggle = () => setIsCollapsed(!isCollapsed);

  return (
    <Row className={`collapsible collapsible-${isCollapsed ? 'collapsed' : 'expanded'}`}>
      <Col md={12}>
        <Styled.HiddenInput
          type="checkbox"
          checked={!isCollapsed}
          onChange={toggle}
          name={label}
          hidden
        />
        <Styled.ToggleWrapper className="collapsible-toggle-wrapper">
          <Styled.ToggleLabel
            data-testid={`collapsible-toggle-${label}`}
            type="button"
            onClick={toggle}
            aria-label={`${isCollapsed ? 'open' : 'close'} ${label}`}
            aria-expanded={isCollapsed ? 'false' : 'true'}
          >
            <Styled.ToggleIconWrapper>
              {isCollapsed ? <ClosedIcon title="Open" /> : <OpenIcon title="Close" />}
            </Styled.ToggleIconWrapper>
            {label}
          </Styled.ToggleLabel>
          {customContent && <Styled.CustomContent>{customContent}</Styled.CustomContent>}
        </Styled.ToggleWrapper>
      </Col>
      {!isCollapsed && (
        <Col md={12}>
          <Styled.CollapsibleAreaWrapper className="collapsible-area-wrapper">
            {children}
          </Styled.CollapsibleAreaWrapper>
        </Col>
      )}
    </Row>
  );
}
