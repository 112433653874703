import { ButtonSize } from '../../../../form/Button';
import ProgressButton from '../../../../form/ProgressButton';

type SignupSubmitButtonProps = {
  loading: boolean;
  disabled?: boolean;
  label?: string;
  size?: ButtonSize;
};

export default function SignupSubmitButton({
  loading = false,
  disabled,
  label = 'Create your FREE account',
  size = 'xLarge',
}: SignupSubmitButtonProps) {
  return (
    <ProgressButton
      type="submit"
      variant="info"
      size={size}
      fullWidth
      progress={loading}
      disabled={disabled}
    >
      {label}
    </ProgressButton>
  );
}
