import { RefObject, useState } from 'react';
import { SignupFormValues } from '../components/pages/loggedout/signup/SignupFormValues';
import { TurnstileWidgetRef } from '../components/pages/loggedout/turnstile/TurnstileWidget';
import useCloudflareChallengeHandlingXhr from './useCloudflareChallengeHandlingXhr';

type LegacySignupAddress = {
  full_name: string;
  company: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  region_id: string;
};

type LegacySignupPayload = {
  user: {
    email: string;
    password: string;
  };
  address: LegacySignupAddress & {
    phone: string;
  };
  use_address_as_return: boolean;
  return: LegacySignupAddress;
  cp: boolean;
  accepted_terms_of_service_actively: boolean;
  signup_source: string;
};

type LegacySignupResult = {
  redirect?: string; // only set if not error occured
};

type LegacySignupResultWithError = LegacySignupResult & {
  error?: string;
};

type UseLegacySignupTuple = [
  request: (
    values: SignupFormValues,
    pasted: boolean,
    signupSource?: string,
  ) => Promise<LegacySignupResult>,
  state: {
    loading: boolean;
    error?: string;
  },
];

export default function useSignup(
  turnstileRef?: RefObject<TurnstileWidgetRef>,
): UseLegacySignupTuple {
  const [error, setError] = useState<string | undefined>(undefined);
  const { handler: cloudflareChallengeHandlingXhr, loading } =
    useCloudflareChallengeHandlingXhr<LegacySignupResultWithError>(turnstileRef);

  const legacySignupRequest = async (
    {
      email,
      password,
      useOriginAsReturnAddress,
      originAddress,
      returnAddress,
      agree,
    }: SignupFormValues,
    pasted: boolean,
    signupSource?: string,
  ): Promise<LegacySignupResult> => {
    setError(undefined);

    const payload: LegacySignupPayload = {
      user: {
        email,
        password,
      },
      address: {
        full_name: originAddress.fullName,
        company: originAddress.company,
        phone: originAddress.phone ?? '',
        address1: originAddress.address1,
        address2: originAddress.address2,
        postcode: originAddress.postcode,
        city: originAddress.city,
        region_id: originAddress.regionCode,
      },
      use_address_as_return: useOriginAsReturnAddress,
      return: {
        full_name: returnAddress.fullName,
        company: returnAddress.company,
        address1: returnAddress.address1,
        address2: returnAddress.address2,
        postcode: returnAddress.postcode,
        city: returnAddress.city,
        region_id: returnAddress.regionCode,
      },
      cp: pasted,
      accepted_terms_of_service_actively: agree,
      signup_source: signupSource ?? '',
    };

    const responseData = await cloudflareChallengeHandlingXhr({
      method: 'POST',
      url: '/signup/do',
      attrs: payload,
    });

    if (responseData.error) {
      setError(responseData.error);
      return {};
    }

    return responseData;
  };

  return [
    legacySignupRequest,
    {
      loading,
      error,
    },
  ];
}
