import { useReactiveVar } from '@apollo/client';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import FlashMessages from '../../components/FlashMessages';
import ProgressButton from '../../components/form/ProgressButton';
import { Col, Container, Row } from '../../components/layout/Grid';
import PageLoading from '../../components/loading/PageLoading';
import Modal from '../../components/modals/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import { useDownloadLabelsByShipmentsQuery } from '../operations/downloadLabelsByShipments.query';
import downloadUrl from '../utils/downloadUrl';
import PageLayoutSelect from './PageLayoutSelect';
import PdfPreview from './PdfPreview';

type ShipmentsPrintModalProps = {
  open: boolean;
  onClose: () => void;
  shipmentIds: string[];
};

export default function ShipmentsPrintModal({
  open,
  onClose,
  shipmentIds,
}: ShipmentsPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const { data, loading, refetch, startPolling, stopPolling } = useDownloadLabelsByShipmentsQuery({
    variables: { shipmentIds },
    skip: !open, // Run query once the modal is open
    notifyOnNetworkStatusChange: true, // Call onCompleted after each fetch
    onError: () => {
      stopPolling();
    },
    onCompleted: ({ downloadLabelsByShipments: { status } }) => {
      switch (status) {
        case 'IDLE':
        case 'RUNNING':
          startPolling(1000);
          break;
        case 'ERROR':
        case 'FINISHED':
          stopPolling();
          break;
        default:
      }
    },
  });

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null;
    }
    if (
      !loading &&
      // correct status to show the print preview
      data !== undefined &&
      data.downloadLabelsByShipments.pageLayout &&
      data.downloadLabelsByShipments.fileFormat &&
      data.downloadLabelsByShipments.url &&
      data.downloadLabelsByShipments.status === 'FINISHED'
    ) {
      const { pageLayout, fileFormat, url } = data.downloadLabelsByShipments;

      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(newPageLayout) => {
                  refetch({
                    shipmentIds,
                    pageLayout: newPageLayout,
                  });
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col md={12} spaceBelow>
              <ProgressButton
                data-testid="download-label-button"
                variant="success"
                fullWidth
                onClick={(event) => {
                  event.preventDefault();
                  downloadUrl(url);
                }}
              >
                Download Label
              </ProgressButton>
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
