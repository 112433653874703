import { getIn, useFormikContext } from 'formik';
import { SPACING } from '../../styles/spacing';
import IntercomArticleLink from '../IntercomArticleLink';
import Checkbox from '../form/Checkbox';
import FormControl from '../form/FormControl';
import Label from '../form/Label';
import TextField from '../form/TextField';
import { Col, Row } from '../layout/Grid';
import AddressSubform, { AddressSubformValues } from './AddressSubform';
import { NamespacedSubform } from './types';

export type WarehouseSubformValues = {
  title: string;
  saveAddressForReuse: boolean;
  originAddress: AddressSubformValues;
  returnAddress: AddressSubformValues;
  useOriginAsReturnAddress: boolean;
};

export default function WarehouseSubform<NS extends string>({ namespace }: NamespacedSubform<NS>) {
  // We don't know about the type of 'values', we just know that the property
  // identified by 'namespace' is WarehouseSubformValues
  const { values } = useFormikContext<Record<string, unknown>>();
  const { useOriginAsReturnAddress, saveAddressForReuse } = getIn(
    values,
    namespace,
  ) as WarehouseSubformValues;
  type ValidNamespaces = `${NS}.${keyof WarehouseSubformValues}`;

  return (
    <Row>
      <Col md={12}>
        <Label
          secondary={
            <>
              Enter the physical home, office, or warehouse address you&lsquo;re shipping from.{' '}
              <IntercomArticleLink href="https://support.pirateship.com/en/articles/1995669-how-do-i-edit-my-ship-from-address">
                Learn more
              </IntercomArticleLink>
            </>
          }
        >
          Physical Address{' '}
        </Label>
      </Col>
      <Col md={12}>
        <AddressSubform<ValidNamespaces> phone="US" namespace={`${namespace}.originAddress`} />
      </Col>

      <Col md={12} spaceBelow>
        <FormControl
          name={`${namespace}.useOriginAsReturnAddress`}
          type="checkbox"
          as={Checkbox}
          label="Use this address as the Return Address on my shipping labels"
        />
      </Col>

      {!useOriginAsReturnAddress && (
        <Col md={12}>
          <Label
            secondary={
              <>
                Enter the address you&apos;d like printed on your shipping labels.{' '}
                <IntercomArticleLink href="https://support.pirateship.com/en/articles/1995669-how-do-i-edit-my-ship-from-address">
                  Learn more
                </IntercomArticleLink>
              </>
            }
          >
            Return Address (optional){' '}
          </Label>
          <AddressSubform<ValidNamespaces> namespace={`${namespace}.returnAddress`} />
        </Col>
      )}
      <Col>
        <Row spaceBelow>
          <Col md={12} style={{ paddingBottom: saveAddressForReuse ? SPACING.md : SPACING.none }}>
            <FormControl
              type="checkbox"
              as={Checkbox}
              name={`${namespace}.saveAddressForReuse`}
              label="Save Ship From Address"
              secondaryText="Save this address to use again later"
            />
          </Col>
          {saveAddressForReuse && (
            <Col md={12}>
              <FormControl
                name={`${namespace}.title`}
                as={TextField}
                label="Nickname this Ship From Address"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
