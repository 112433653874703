import { LazyQueryExecFunction } from '@apollo/client';
import { downloadUrl } from '@ps/printLabels';
import { BatchEmailNotificationRow, ScheduleEmailNotificationModal } from '@ps/trackingEmails';
import { useState } from 'react';
import { CarrierIcon } from '../../components/Icon';
import { LinkButton } from '../../components/Link';
import QuestionIconTooltip from '../../components/QuestionIconTooltip';
import HeaderRow from '../../components/serviceSummaryTable/HeaderRow';
import ServiceSummaryTable from '../../components/serviceSummaryTable/StyledServiceSummaryTable';
import TotalCostRow from '../../components/serviceSummaryTable/TotalCostRow';
import { BatchPriceSummary, Exact, FailedShipmentsExportQuery } from '../../gql/graphql';
import useIsMobile from '../../hooks/useIsMobile';
import { COLOR } from '../../styles/colors';
import { formatCurrency } from '../../utils/currency';
import calculateTotalCostFromPriceSummary from '../utils/calculateTotalCostFromPriceSummary';
import getAdditionalTrackingEmailCopies from '../utils/getAdditionalTrackingEmailCopies';
import renderPerShipmentPriceInfo from '../utils/renderPerShipmentPriceInfo';
import ShipDateRow from './ShipDateRow';

type BatchServiceSummaryTableProps = {
  batch: {
    id: string;
    notifyRecipientsDate: string | null;
    emailNotificationPossible: boolean;
    dataSource: string;
    emailNotificationSent: boolean;
    status: string;
    priceSummary: ReadonlyArray<BatchPriceSummary>;
    shipDate: string;
    shipmentStatusSummary: {
      errorCount: number;
    };
    shipments: ReadonlyArray<{
      order: { platform: { additionalTrackingEmailCopy: string } } | null;
    }>;
    emailNotificationTemplate: { id: string } | null;
  };
  onClickFailedShipmentsExport: LazyQueryExecFunction<
    FailedShipmentsExportQuery,
    Exact<{
      batchId: string;
    }>
  >;
};

export default function BatchServiceSummaryTable({
  batch,
  onClickFailedShipmentsExport,
}: BatchServiceSummaryTableProps) {
  const { isMobile } = useIsMobile();
  const [scheduleEmailNotificationModalOpen, setScheduleEmailNotificationModalOpen] =
    useState<boolean>(false);

  const renderIcon = (priceSummary: BatchPriceSummary) => {
    if (priceSummary.surcharge) return false;
    if (priceSummary.carrierKeys.length !== 1) return false;
    return (
      <CarrierIcon
        carrierKey={priceSummary.carrierKeys[0]}
        mailClassKey={priceSummary.pirateShipService ? 'FirstGlobalRate' : ''} // FirstGlobalRate is the key of the Simple Export Rate
      />
    );
  };

  const { errorCount } = batch.shipmentStatusSummary;

  return (
    <>
      <ScheduleEmailNotificationModal
        batchId={batch.id}
        selectedTemplateId={batch.emailNotificationTemplate?.id}
        open={scheduleEmailNotificationModalOpen}
        onClose={() => setScheduleEmailNotificationModalOpen(false)}
        notifyRecipientsDate={batch.notifyRecipientsDate}
      />
      <ServiceSummaryTable.Content>
        <HeaderRow />
        {batch.priceSummary.map((priceSummary) => (
          <ServiceSummaryTable.Row
            white
            key={`${priceSummary.title}-${priceSummary.isRefunded ? 'refunded' : 'not-refunded'}`}
            color={priceSummary.isRefunded ? COLOR.red : undefined}
          >
            <ServiceSummaryTable.Col>
              <ServiceSummaryTable.ServiceTitleWrapper data-testid="service-title">
                {renderIcon(priceSummary)}
                {`${priceSummary.shipmentCount} × ${priceSummary.isRefunded ? 'Refunded ' : ''}${
                  priceSummary.title
                } ${renderPerShipmentPriceInfo(priceSummary)}`}
              </ServiceSummaryTable.ServiceTitleWrapper>
            </ServiceSummaryTable.Col>
            <ServiceSummaryTable.Col alignRight>
              {priceSummary.isRefunded ? (
                <ServiceSummaryTable.Strikethrough data-testid="price">
                  {formatCurrency(-priceSummary.totalPrice)}
                </ServiceSummaryTable.Strikethrough>
              ) : (
                <ServiceSummaryTable.Price data-testid="price">
                  {priceSummary.isReturnLabel
                    ? `${formatCurrency(priceSummary.usedReturnPrice)} of ${formatCurrency(
                        priceSummary.totalPrice,
                      )} max (${priceSummary.returnsUsed} used)`
                    : formatCurrency(priceSummary.totalPrice)}
                </ServiceSummaryTable.Price>
              )}
            </ServiceSummaryTable.Col>
          </ServiceSummaryTable.Row>
        ))}
        {Boolean(errorCount) && (
          <ServiceSummaryTable.Row white key="priceSummaryErrors" color={COLOR.red}>
            <ServiceSummaryTable.Col>
              <ServiceSummaryTable.ServiceTitleWrapper>
                {`${errorCount} × ${errorCount > 1 ? 'Errors' : 'Error'}`}
                <LinkButton
                  onClick={async () => {
                    const result = await onClickFailedShipmentsExport({
                      variables: { batchId: batch.id },
                    });
                    if (result.data?.batch.failedShipmentsExportStatus.url) {
                      downloadUrl(result.data.batch.failedShipmentsExportStatus.url);
                    }
                  }}
                >
                  <>
                    {isMobile ? '(Export Data)' : '(Export to Edit & Start a New Batch)'}
                    <QuestionIconTooltip>
                      There was an error with 1 or more labels in your spreadsheet. You were not
                      charged for these labels. Download the file to correct the error, then upload
                      to purchase your labels 👍
                    </QuestionIconTooltip>
                  </>
                </LinkButton>
              </ServiceSummaryTable.ServiceTitleWrapper>
            </ServiceSummaryTable.Col>
            <ServiceSummaryTable.Col alignRight>
              <ServiceSummaryTable.Price>{formatCurrency(0)}</ServiceSummaryTable.Price>
            </ServiceSummaryTable.Col>
          </ServiceSummaryTable.Row>
        )}
        <TotalCostRow currentCost={calculateTotalCostFromPriceSummary(batch.priceSummary)} />
        <ShipDateRow shipDate={batch.shipDate} isBatch />
        <BatchEmailNotificationRow
          onClickDate={() => setScheduleEmailNotificationModalOpen(true)}
          additionalTrackingEmailCopies={getAdditionalTrackingEmailCopies(batch)}
          batch={batch}
        />
      </ServiceSummaryTable.Content>
    </>
  );
}
