import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import useCurrentUser from '../../hooks/useCurrentUser';
import useNavigateOrHref from '../../hooks/useNavigateOrHref';
import sendUserEmailVerificationLinkMutation from '../../operations/mutations/sendUserEmailVerificationLink';
import { AutoGridRow } from '../layout/Grid';
import Modal, { ModalButtonBar } from './Modal';

/**
 * Exported for usage in component tests
 */
// eslint-disable-next-line react-refresh/only-export-components
export const REASONS: Record<string, string> = {
  generic: 'We could not verify your email',
  expired: 'That verification link was expired',
};

export const verifyUserEmailFailedModalTriggerFragment = '#user-email-verification-failed';

/**
 * This Modal is used after the user email verification has succeeded
 */
export default function UserEmailVerificationFailedModal() {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const navigateOrHref = useNavigateOrHref();
  const { pathname, search, hash } = useLocation();
  const [open, setOpen] = useState(false);
  const [resending, setResending] = useState<boolean>(false);
  const [sendUserEmailVerificationLink] = useMutation(sendUserEmailVerificationLinkMutation, {
    errorPolicy: 'all',
  });
  const [reason, setReason] = useState(REASONS.generic);

  // Open modal, if requested via URL fragment.
  // If the user is logged, only open if email has not been verified yet.
  useEffect(() => {
    const hashMatches = hash.startsWith(verifyUserEmailFailedModalTriggerFragment);

    if (hashMatches) {
      const hashParts = hash.split('=', 2);
      setReason(
        hashParts.length === 2 && Object.keys(REASONS).includes(hashParts[1])
          ? REASONS[hashParts[1]]
          : REASONS.generic,
      );
    }

    const emailNotVerified = currentUser ? !currentUser.emailVerified : true;

    setOpen(hashMatches && emailNotVerified);
  }, [hash, currentUser]);

  const handleClose = useCallback(() => {
    navigate(`${pathname}${search}`, {
      replace: true,
    });
  }, [navigate, pathname, search]);

  /**
   * Handle email related events
   */
  const handleResendEmailEvent = useCallback(async () => {
    setResending(true);
    const result = await sendUserEmailVerificationLink(); // may fail with error on rate limited
    setResending(false);

    // we need to "persist" the flash messages, as the handleClose will call navigate once
    if (!result.data?.sendUserEmailVerificationLink || result.errors?.length) {
      setFlashMessage(
        `Failed to send email verification link${
          result.errors?.length ? ` - ${result.errors[0].message}` : ''
        }`,
        'danger',
        -1,
        true,
      );
    } else {
      setFlashMessage('Successfully sent email verification link', 'success', -1, true);
    }

    handleClose();
  }, [handleClose, sendUserEmailVerificationLink]);

  const handleRedirectToLogin = useCallback(() => {
    navigateOrHref('/');
  }, [navigateOrHref]);

  return (
    <Modal
      width={800}
      open={open}
      title={`Error: ${reason} 🤔`}
      data-testid="user-email-verification-failed-modal"
      onClose={handleClose}
    >
      <AutoGridRow>
        <p>
          {currentUser
            ? 'Sorry Captain, you’ll need to click resend to get a new link and try again.'
            : 'Sorry Captain, you’ll need to login to get a new link and try again.'}
        </p>
      </AutoGridRow>

      <ModalButtonBar
        buttonText={currentUser ? 'Resend Verification Email' : 'Close & Login'}
        buttonOnClick={currentUser ? handleResendEmailEvent : handleRedirectToLogin}
        buttonVariant="primary"
        buttonProgress={resending}
        buttonDisabled={resending}
      />
    </Modal>
  );
}
